import React, {
  useState,
  useCallback,
  useId,
  forwardRef,
  useImperativeHandle,
  ReactNode,
} from 'react';

import { useSetRecoilState } from 'recoil';

import { Icon } from '@components/Icon';
import _ from 'lodash';
import Modal from '@components/Modal/Modal';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { isValidFileSize } from 'src/@utils/isValidFileSize';

interface MultipleImageInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  setImage?: (files: FileList) => void;
  maxSize?: number;
  label?: string;
  defaultUrls?: string[];
  viewOnly?: boolean;
  showIndex?: boolean;
  modify?: boolean;
  previewWrapperClassName?: string;
  previewBoxClassName?: string;
  emptyInputClassName?: string;
  labelNode?: ReactNode;
  previewFooterNode?: (previewUrl: string, index: number) => ReactNode;
}

interface MultipleImageInputHandle {
  getOriginFiles: () => FileList | null;
  getPreviewUrls: () => string[];
}

const MultipleImageInput = React.memo(
  forwardRef<MultipleImageInputHandle, MultipleImageInputProps>(
    (
      {
        setImage,
        maxSize = 20,
        multiple = true,
        label = '',
        defaultUrls = [],
        viewOnly = false,
        showIndex = false,
        previewBoxClassName,
        previewWrapperClassName,
        emptyInputClassName,
        modify,
        labelNode,
        previewFooterNode: previewBottomNode,
        ...props
      },
      ref,
    ) => {
      const uuid = useId();
      const fileSizeModalId = `file-size-modal-${uuid}`;
      const inputId = `multiple-file-uploader-${uuid}`;
      const [originFiles, setOriginFiles] = useState<FileList | null>(null);
      const [previewUrls, setPreviewUrls] = useState<string[]>(defaultUrls);
      const setAlert = useSetRecoilState(modalOpenAtomFamily(fileSizeModalId));

      const isSelectedFiles = previewUrls.length > 0;

      useImperativeHandle(ref, () => ({
        getOriginFiles: () => originFiles,
        getPreviewUrls: () => previewUrls,
      }));

      const updateFileList = useCallback(
        (filesArray: File[], setImage?: (files: FileList) => void) => {
          const newFileList = new DataTransfer();
          filesArray.forEach((file) => {
            newFileList.items.add(file);
          });
          const newFiles = newFileList.files;
          setImage && setImage(newFiles);
          return newFiles;
        },
        [],
      );

      const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          const files = event.target.files;
          if (files) {
            //업로드 한 파일 용량 체크
            const allFilesWithinLimit = Array.from(files).every((file) =>
              isValidFileSize(file, maxSize),
            );
            if (!allFilesWithinLimit) {
              setAlert(true);
              return;
            }

            const newPreviewUrls = _.map(files, (file) => {
              return URL.createObjectURL(file);
            });

            // FileList를 배열로 변환하고 이전 파일 배열과 합침
            const newFilesArray = Array.from(files);
            const prevFilesArray = originFiles ? Array.from(originFiles) : [];
            const combinedFilesArray = [...prevFilesArray, ...newFilesArray];

            // 다시 FileList 객체로 변환
            const combinedFileList = new DataTransfer();
            _.forEach(combinedFilesArray, (file) => {
              combinedFileList.items.add(file);
            });

            setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
            setOriginFiles(combinedFileList.files);
            setImage && setImage(combinedFileList.files);
          }

          event.target.value = ''; //동일한 이미지 추가 선택 작동을 위한 초기화
        },
        [maxSize, originFiles, setAlert, setImage],
      );

      const handleRemove = useCallback(
        (index: number) => {
          // 미리보기에서 해당 이미지 제거
          setPreviewUrls((prevUrls) => {
            const urlToRevoke = prevUrls[index] ?? '';
            URL.revokeObjectURL(urlToRevoke);
            return prevUrls.filter((_, i) => i !== index);
          });
          // FileList에서 해당 파일 제거
          if (originFiles) {
            const filesArray = Array.from(originFiles);
            filesArray.splice(index, 1);
            const newFiles = updateFileList(filesArray, setImage);
            setOriginFiles(newFiles);
          } else {
            const newFiles = updateFileList([], setImage);
            setOriginFiles(newFiles);
          }
        },
        [originFiles, setImage, updateFileList],
      );

      const handleEdit = useCallback(
        (index: number) => {
          const input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/*';
          input.onchange = (e: Event) => {
            const target = e.target as HTMLInputElement;
            const newFile = target.files ? target.files[0] : null;
            if (newFile) {
              if (!isValidFileSize(newFile, maxSize)) {
                setAlert(true);
                return;
              }
              const newUrl = URL.createObjectURL(newFile);
              // 미리보기에서 해당 이미지 교체
              setPreviewUrls((prevUrls) => {
                const urlToRevoke = prevUrls[index] ?? '';
                URL.revokeObjectURL(urlToRevoke);
                const newUrls = [...prevUrls];
                newUrls[index] = newUrl;
                return newUrls;
              });
              // FileList에서 해당 파일 교체
              if (!originFiles) {
                const newFileList = new DataTransfer();
                newFileList.items.add(newFile);
                setOriginFiles(newFileList.files);
                setImage && setImage(newFileList.files);
              } else {
                //이미 존재한다면 해당 index 수정
                const filesArray = Array.from(originFiles);
                filesArray[index] = newFile;
                const newFiles = updateFileList(filesArray, setImage);
                setOriginFiles(newFiles);
              }
            }
          };
          input.click();
        },
        [maxSize, originFiles, setAlert, setImage, updateFileList],
      );

      const SelectLabel = useCallback(
        () => (
          <div
            className={`${
              emptyInputClassName ? emptyInputClassName : 'center-box h-full w-full p-2'
            }`}
          >
            <div className="text-left text-12 text-gray-500">
              <Icon.Image2 className="mb-1" />
              <p className="mb-1">사진을 선택해 주세요.</p>
              <p>{`PNG, JPG up to ${maxSize}MB`}</p>
            </div>
          </div>
        ),
        [emptyInputClassName, maxSize],
      );

      const FileSizeAlertMdoal = useCallback(() => {
        return (
          <Modal
            className="bg-white px-6 py-6"
            openId={fileSizeModalId}
            position="center"
          >
            <div>
              <p className="py-4 text-20 font-semibold">{`이미지 파일의 크기는 ${maxSize}MB 이하여야 합니다.`}</p>
              <button
                className="w-full rounded-md bg-red-500 py-4 text-white"
                onClick={() => setAlert(false)}
              >
                확인
              </button>
            </div>
          </Modal>
        );
      }, [fileSizeModalId, maxSize, setAlert]);

      const ImagePreviewBox = useCallback(
        ({ index, url }: { index: number; url: string }) => {
          return (
            <div
              className={`center-box group relative rounded border bg-white ${previewBoxClassName}`}
            >
              {showIndex && (
                <p className="absolute left-1 top-1 text-gray-500">{index + 1}</p>
              )}
              {!viewOnly && (
                <div className="absolute bottom-0  hidden h-8 w-full grid-cols-2 bg-black/60 font-semibold  text-white group-hover:grid">
                  <button
                    type="button"
                    onClick={() => handleEdit(index)}
                    className=" hover:bg-blue-500"
                  >
                    수정
                  </button>
                  <button
                    type="button"
                    onClick={() => handleRemove(index)}
                    className=" hover:bg-red-500"
                  >
                    삭제
                  </button>
                </div>
              )}
              <img
                src={url}
                alt={`preview-${index}`}
                className="h-full w-full object-scale-down"
                loading="lazy"
              />
            </div>
          );
        },
        [handleEdit, handleRemove, previewBoxClassName, showIndex, viewOnly],
      );

      const Label = useCallback(({ label }: { label: string }) => {
        return <p className="text-20 font-semibold">{label}</p>;
      }, []);

      return (
        <div className="flex h-full flex-col gap-y-2">
          <div className="flex items-center gap-x-4 ">
            {labelNode ? labelNode : <Label label={label} />}
            {multiple && isSelectedFiles && modify && (
              <label
                htmlFor={inputId}
                className="cursor-pointer rounded-full border-2 px-3 py-1 transition-all hover:border-black hover:bg-black hover:text-white"
              >
                + 추가
              </label>
            )}
          </div>
          <label
            htmlFor={inputId}
            className={`relative flex h-full w-full cursor-pointer items-center justify-center rounded border bg-white
          ${isSelectedFiles && 'hidden'}
          `}
          >
            <input
              id={inputId}
              type="file"
              className="absolute left-0 h-0 w-0 cursor-pointer opacity-0"
              onChange={handleFileChange}
              accept="image/*"
              multiple={multiple}
              {...props}
            />
            <SelectLabel />
          </label>
          {/* Image */}
          {previewUrls.length > 0 && (
            <div
              className={`${
                previewWrapperClassName
                  ? previewWrapperClassName
                  : 'flex h-full w-full gap-x-4 overflow-x-auto'
              }`}
            >
              {previewUrls.map((url, index) => (
                <div key={url}>
                  <ImagePreviewBox url={url} index={index} />
                  {previewBottomNode && previewBottomNode(url, index)}
                </div>
              ))}
            </div>
          )}
          <FileSizeAlertMdoal />
        </div>
      );
    },
  ),
);

export { MultipleImageInput, MultipleImageInputHandle };
