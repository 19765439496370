import { forwardRef, useCallback, useId, useImperativeHandle, useRef } from 'react';

import { useSetRecoilState } from 'recoil';

import { Image } from '@api/mongo/interfaces';
import { fileBucketUrl } from '@plugin/s3Image';
import { StatusBase } from '@api/mongo/interfaces/status.interface';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { modalOpenAtomFamily } from '@state/atom/openAtom';
import { Icon } from '@components/Icon';

import { ImagePreviewModal } from './ImagePreviewModal';

type Props = {
  label: string;
  taskStatus?: StatusBase;
  output?: Image;
};

const OutputCard = forwardRef(({ label, taskStatus, output }: Props, ref) => {
  const uuid = useId();
  const modalId = `preview-${uuid}`;
  const outputUrl = fileBucketUrl(output?.key ?? '');
  const defaultStyle = 'overflow-hidden rounded border center-box h-full w-full';

  const setPreviewModal = useSetRecoilState(modalOpenAtomFamily(modalId));

  const setBg = () => {
    switch (taskStatus) {
      case StatusBase.Todo:
        return defaultStyle + 'bg-red-100';
      case StatusBase.Doing:
        return defaultStyle + 'bg-yellow-100';
      case StatusBase.Done:
        return defaultStyle + 'bg-green-100';
      default:
        return defaultStyle + 'bg-white-100';
    }
  };

  const TaskStateComp = useCallback(() => {
    switch (taskStatus) {
      case StatusBase.Todo: {
        return <p>Ready...</p>;
      }
      case StatusBase.Doing: {
        return (
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 32, color: 'black' }} spin />}
          />
        );
      }
      case StatusBase.Done: {
        return (
          <div className="relative flex">
            <img
              // ref={imgRef} // 2. 참조를 img 태그에 연결
              loading="lazy"
              src={outputUrl}
              alt={`output-${label}`}
            ></img>
          </div>
        );
      }
      default: {
        return <>Todo</>;
      }
    }
  }, [label, outputUrl, taskStatus]); // status에만 의존해서 업데이트해도 됨

  return (
    <div className="group relative flex flex-col gap-y-2 rounded-md border bg-white p-2 hover:ring-2">
      <div className="flex items-center justify-between">
        <p className="text-left">{label}</p>

        <Tooltip title="크게 보기">
          <button
            type="button"
            className="group- absolute right-0 mr-2 hidden group-hover:block"
            onClick={(e) => {
              e.stopPropagation();
              if (taskStatus === StatusBase.Done) {
                setPreviewModal(true);
              }
            }}
          >
            <Icon.Eye className="h-5 w-5 " />
          </button>
        </Tooltip>
      </div>
      <div className={`${setBg()} group `}>{<TaskStateComp />}</div>
      <ImagePreviewModal openId={modalId} fullUrl={outputUrl} />
    </div>
  );
});
export { OutputCard };
