import { useMemo, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';
import { Drawer } from 'antd';
import ReactRemoveScroll from 'react-remove-scroll/dist/es5/Combination';
import { Icon } from '@components/Icon';

import { LoginButton } from './components/LoginButton';

const menuItems = [
  { to: '/job', label: 'Jobs' },
  { to: '/stats', label: 'Stats' },
  { to: '/batch-job', label: 'BatchJob' },
];

const HeaderContainer = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const menuList = useMemo(() => {
    return menuItems.map((menu) => {
      const isActive = pathname.includes(menu.to);
      return (
        <NavLink key={menu.label} to={menu.to}>
          <div
            className={`relative flex h-fit flex-col items-center justify-center rounded px-4 py-2 transition-all ${
              isActive
                ? 'bg-black font-bold text-white hover:bg-black '
                : 'hover:bg-gray-200'
            }`}
          >
            <p>{menu.label}</p>
          </div>
        </NavLink>
      );
    });
  }, [pathname]);

  const sideNav = useMemo(() => {
    return (
      <ReactRemoveScroll enabled={open}>
        <Drawer
          placement="right"
          contentWrapperStyle={{ width: '60vw' }}
          bodyStyle={{ height: '100%' }}
          onClose={onClose}
          open={open}
        >
          <div className="flex h-full flex-col">
            <div>{menuList}</div>
            <div className="mt-auto self-end">
              <LoginButton />
            </div>
          </div>
        </Drawer>
      </ReactRemoveScroll>
    );
  }, [menuList, open]);

  return (
    <div className="z-50 flex h-[60px] w-full items-center justify-between border-b bg-gray-100 px-10 text-20 max-sm:sticky max-sm:top-0">
      <div className="flex h-full w-full items-center gap-x-4 max-sm:hidden">
        {menuList}
      </div>
      <div className="relative max-sm:hidden">
        <LoginButton />
      </div>

      <div className="hidden w-full max-sm:block">
        <Icon.Menu onClick={showDrawer} className="ml-auto h-8 w-8" />
      </div>
      {sideNav}
    </div>
  );
};
export { HeaderContainer };
