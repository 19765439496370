import { useCallback } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import utc from 'dayjs/plugin/utc';
import locale from 'antd/es/date-picker/locale/ko_KR';
import { DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { JobStatus } from '@api/mongo/interfaces/status.interface';
import { DatePickerProps } from 'antd/es/date-picker';
import dayjs, { Dayjs } from 'dayjs';
import { LoadingSpinnerAntd } from '@components/LoadingSpinnerAntd';
import { userProfileSelector } from '@state/atom/auth.atom';

import { StatsItem } from './components/StatsItem';
import { jobsStatsPollingSelectorFamily } from './state/StatsContainer.selector';
import { jobsStatsSelectedDate } from './state/StatsContainer.atom';
import { useJobsStatsPolling } from './state/statsContainer.hooks';

dayjs.extend(utc);

const StatsContainer = () => {
  const navigate = useNavigate();
  const [statsDate, setStatsDate] = useRecoilState(jobsStatsSelectedDate);
  const userId = useRecoilValue(userProfileSelector)?._id ?? '';

  useJobsStatsPolling({
    userId,
  });
  const { data: statsPollingData, status: statsPollingStatus } = useRecoilValue(
    jobsStatsPollingSelectorFamily({ userId }),
  );

  // 날짜 선택 핸들러
  const onDateRangeHandler: DatePickerProps['onChange'] = useCallback(
    (dates: Dayjs | null) => {
      if (dates) {
        let fromDate;
        //! 런칭 날짜 체크 (2023년 11월 2일)
        if (dates.isSame(dayjs(new Date(2023, 10, 2)), 'day')) {
          fromDate = dayjs(new Date(2023, 10, 2, 11, 0, 0));
        } else {
          fromDate = dayjs(dates).startOf('day');
        }

        setStatsDate(fromDate);
      }
    },
    [setStatsDate],
  );

  // 날짜 비활성화 (23.11.02 ~ 금일)
  const disabledDate = (current: Dayjs) => {
    return (
      current.isAfter(dayjs().endOf('day')) ||
      current.isBefore(dayjs(new Date(2023, 10, 2)))
    );
  };

  return (
    <div className="h-full min-h-screen w-full px-10">
      <div className="mt-8 space-y-8">
        <div className="">
          <p className="mb-1 text-right text-18 font-semibold text-red-500">
            * 선택된 날짜의 00:00:00부터 집계됩니다.
          </p>
          <div className="flex w-full items-center justify-end">
            <p className="mr-2 text-20">시작일 :</p>
            <DatePicker
              inputReadOnly
              className={`rounded-md px-3 py-2.5 shadow-sm`}
              locale={locale}
              defaultValue={statsDate}
              onChange={onDateRangeHandler}
              clearIcon={false}
              disabledDate={disabledDate}
            />
          </div>
        </div>

        {/* status list */}
        <div className="space-y-4 rounded-md bg-white p-4">
          {statsPollingStatus === 'success' && statsPollingData ? (
            <div className="grid grid-cols-7 gap-x-4 max-sm:grid-cols-2 max-sm:gap-y-4">
              {Object.values(JobStatus).map((item) => {
                return (
                  <StatsItem
                    key={item}
                    status={item}
                    count={statsPollingData[item]}
                    onClick={() => {
                      navigate(`/job?status=${item}`);
                    }}
                  />
                );
              })}
            </div>
          ) : (
            <LoadingSpinnerAntd size={30} />
          )}
        </div>
      </div>
    </div>
  );
};
export { StatsContainer };
